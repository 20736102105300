$wide-layout-min-size: 1024px;
$bright-background: #D1BC7F;

body {
  text-stroke: 0.35px;
}

@media screen and (min-width: $wide-layout-min-size) {
  body {
    padding: 0 200px 0 0;
  }
}

.container-narrow {
  background-color: $bright-background;
  color: black;
  &:nth-child(2) {
    padding-top: 50px;
  }
  .row-fluid > {
    h1, h2 {
      margin-top: 0px;
    }
  }
}

@media screen and (min-width: $wide-layout-min-size) {
  .container-narrow {
    margin: auto;
    max-width: 900px;
  }
}

footer {
  text-align: center;
}

.navbar {
  position: fixed;
  width: 100%;
  height: 40px;
  z-index: 1000;
  background-color: $bright-background;
  border: 0;
  top: -2px;
  .navbar-inner {
    top: -1px;
    height: 40px;
    z-index: 1000;
    box-shadow: 0px 10px 10px rgba(50, 50, 50, 0.5);
  }
  .navbar-header {
    margin-left: 5px;
    line-height: 40px;
    font-size: 32px;
  }
}

.container-narrow {
  /* first panel */
  div {
    &.brand-container {
      position: relative;
      padding-bottom: 50px;
    }
    &.brand {
      position: relative;
      box-sizing: border-box;
      div, h3, p {
        box-sizing: border-box;
      }
      h3 {
        margin-bottom: 0px;
        line-height: 25px;
        color: #486726;
        text-transform: uppercase;
        font-family: "Stardos Stencil";
      }
      h4 {
        color: black;
        font-size: 18px;
        font-weight: bold;
      }
      p > a {
        word-wrap: break-word;
        &:before {
          content: "» ";
        }
        &:link {
          color: white;
        }
      }
      .body {
        color: black;
        font-size: 12pt;
        p {
          margin: 0;
        }
      }
      .brand-part {
        display: inline-block;
        width: 250px;
        vertical-align: top;
        img {
          vertical-align: initial;
        }
        &:first-child {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: $wide-layout-min-size) {
  .container-narrow div.brand {
    padding: 0 20px 0 20px;
    position: fixed;
    top: 0px;
    margin-left: auto;
    top: -280px;
    display: inline-block;
    width: 300px;
    background-image: url(../img/Rahmen.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    h3 {
      margin-top: 10px;
      padding: 0 20px 0 20px;
      width: 260px;
      text-align: center;
    }
    h4 {
      display: inline-block;
      margin-top: 5px;
      margin-bottom: 0;
      padding: 0;
    }
    .body {
      padding: 0 30px 0 30px;
      line-height: 1.2;
    }
    .brand-part {
      display: block;
      width: inherit;
    }
  }
}

@media screen and (min-width: $wide-layout-min-size) {
  .container-narrow div.brand:nth-child(1) {
    right: 10px;
    top: -130px;
    /*	&:hover {
				top: -80px;
			}
		*/
    img {
      margin-left: 20px;
      margin-top: 170px;
    }
  }
}